.wrapper{

        display: block;
        text-align: left;
        max-width: 770px;
        margin: auto;


     
        -moz-box-sizing: border-box; 
        -webkit-box-sizing: border-box; 
        box-sizing: border-box;

}

.wrapper_link{
    text-decoration: none;
}

.description{
    
    vertical-align: top;
    margin:auto;
    text-align: center;

    
   /* position: relative;
   top:-40px;
  
   
   height: 50px; */
}

.title{
    padding-left: 10px;
    padding-top: 6px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 29px;

    color: #285CA6;
}



.img{
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
    display: block;
    margin: 20px auto;
    vertical-align: middle;
    text-align: center;
    width: 70%;
}

.img_logo{
   display: inline-block;
    vertical-align: middle;
    width:  100%;
    height: 100%;

   /* margin-left: 3px; */
    border-radius: 17px;


}
.category_img{
    margin-top: -40px;
    width:56px;
    position: relative;
    top:-20px;
   float: right;
    margin-right: 5px;
}

.link {
    display: block;
    text-align: center;
    margin: 10px auto 10px auto;
    width: 100px;
    height: 30px;
    text-decoration: none;
    background: #3681C3;
    border-radius: 20px;
    cursor: pointer;
}

.linkText{

    display: inline-block;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
    align-content: center;
    text-align: center;
    color: #FFFFFF;
    width:100px ;


}

.count_wrapper{
    display: inline-block;
    vertical-align: middle;

    margin-top: 10px;
}

.wrapper_buttons{
    text-align: center;
    width: 90%;
    margin: auto;
}
/*@media (max-width: 1480px) {*/


/*    .wrapper{*/
/*        width: 770px;*/
/*        height: 106px;*/
/*        margin: auto;*/
/*    }*/
/*    .description{*/
/*        width: 70%;*/
/*    }*/
/*    .img{*/
/*        !* display: inline-block;*/
/*        -moz-box-sizing: border-box; */
/*        -webkit-box-sizing: border-box; */
/*        box-sizing: border-box; *!*/
/*        width: 30%;*/
/*        text-align: right;*/
/*     }*/

/*     .link {*/
/*        display: block;*/
/*        position: relative;*/
/*        top:-50px;*/
/*        left:60%;*/
/*       */
/*    }*/
/*    .img_logo{*/
/*        position: relative;*/
/*        top:-1px;*/
/*        right: -1px;*/
/*        width: 201px;*/
/*        height: 102px;*/
/*     }*/

/*    .category_img{*/
/*        width:56px;*/
/*        position: relative;*/
/*        top:-59px;*/
/*        left:-2px;*/
/*    }*/
/*}*/

/*@media (max-width: 830px) {*/

/*    .wrapper{*/
/*        width: 650px;*/
/*        height: 110px;*/
/*    }*/

/*    .category_img{*/
/*        width:56px;*/
/*        position: relative;*/
/*        top:-59px;*/
/*        left:-2px;*/
/*    }*/


/*     */
/*     .link {*/
/*        display: block;*/
/*        position: relative;*/
/*        top:-40px;*/
/*        text-align: right;*/
/*        left:52%;*/
/*       */
/*    }*/

/*    .img{*/
/*        display: inline-block;*/
/*        -moz-box-sizing: border-box; */
/*        -webkit-box-sizing: border-box; */
/*        box-sizing: border-box;*/
/*        width: 30%;*/
/*        text-align: right;*/
/*     */
/*     }*/

/*    .img_logo{*/
/*        */
/*        position: relative;*/
/*        !* top:1px; *!*/
/*        left: -17px;*/
/*       */
/*        height: 105px;*/
/*       */
/*     }*/
/*}*/
/* */
/*@media (max-width: 765px) {*/
/*    .title{*/
/*        display: block;*/
/*        width: 90%;*/
/*        text-align: left;*/
/*    }*/
/*    .wrapper{*/
/*        position: static;*/
/*        display: block;*/
/*        height: auto;*/
/*        min-height: 240px;*/
/*        max-width: 620px;*/
/*        width: 100%;*/
/*    }*/


/*    .description{*/
/*        position: static;*/
/*        width: 95%;*/
/*        display: block;*/
/*        min-height: 20px;*/
/*        margin: 10px 20px;*/

/*    }*/
/*    .img{*/
/*        position: static;*/
/*        width: 50%;*/
/*        display: block;*/
/*        margin: auto;*/

/*        text-align: right;*/
/*        -moz-box-sizing: border-box;*/
/*        -webkit-box-sizing: border-box;*/
/*        box-sizing: border-box;*/
/*        !*margin-bottom: -80px;*!*/
/*     }*/

/*     .img_logo{*/

/*        width: 100%;*/
/*        height: 100%;*/
/*     */
/*        border-radius: 17px;*/
/*        margin-left: 18px;*/
/*     }*/

/*    .category_img{*/
/*        width:45px;*/

/*        position: relative;*/
/*        top:-105px;*/
/*        left:-3px;*/
/*        margin-bottom: -100px;*/
/*    }*/
/*     */
/*     .link {*/
/*        display: block;*/
/*        position: static;*/
/*        margin: 10px auto;*/
/*       */
/*    }*/
/*}*/

/*@media (max-width: 390px) {*/
/*    .title{*/
/*        display: block;*/
/*        width: 90%;*/
/*        text-align: left;*/
/*        font-size: 18px;*/
/*    }*/
/*    .description{*/
/*        position: static;*/
/*        width: 90%;*/

/*    }*/

/*    .wrapper{*/
/*       */
/*        min-height: 190px;*/
/*    */
/*    }*/
/*    .text{*/
/*       width: 90%;*/
/*    }*/
/*}*/
/* */
