.wrapper{
    width: 96%;
    height: auto;

    margin: 10px auto 0 auto;
}

/* .quiz_name{
    width:80%;
    max-width: 1450;
    margin: auto;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 18px;
    color: #285CA6;
} */

.message_not_question{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 39px;
    margin-top: 10px;
    color: #c43b12;
}

.bodyAsk{
    height: auto;
    max-width: 1450px;
    margin: auto;
}

@media (max-width: 770px) {
    .wrapper{
        
        margin: 50px auto 0 auto;

    }
    .quiz_name{
        padding-top: 10px;
        text-align: left;
    }
}
@media (max-width: 400px) {

    .quiz_name{
        text-align: center;
    }
}