.wrapper{
    z-index: 10007;
    overflow-y: auto;
    margin: 90px auto;
    position: fixed;
    left: 50%;
    top: 5%;
    /*top:40px;*/
    width: 80%;
    transform: translateX(-50%);
    background-color: white;
    border:3px solid #285CA6;
    border-radius: 20px;
    max-width: 700px;
    padding: 10px;
}

.background{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(224, 224, 224, 0.6);
    z-index: 10006;
}
.text{
    font-family: 'Montserrat';
    font-weight: 800;
    color: #285CA6;
    font-size: 16px;
    padding: 10px;
    margin:  auto;
}
.btn_wrapper{
    margin: auto;
    text-align: center;
}
.btn_next,
.btn_close{
    background: #FFFFFF;
    border: 3px solid #3681C3;
    box-sizing: border-box;
    border-radius: 20px;
    width: 190px;
    height: 30px;
    margin: 10px 10px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;


    text-align: center;

    color: #285CA6;
    cursor: pointer;
    display: inline-block;
}

.btn_next{
    background: #3681C3;
    color: #FFFFFF;
}

